
export const equals = (a1, a2, checkOrder:boolean) => {
  if(checkOrder) {
    //並び順まで含めて一致を確認する
    return JSON.stringify(a1) == JSON.stringify(a2);
  }
  else {
    //内容があってればOK
    return JSON.stringify([...a1].sort()) == JSON.stringify([...a2].sort());
  }
}


//A not B;
export const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
}

//A and B;
export const and = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
}

//A + B
export const union = (a, b) => {
  return [...a, ...not(b, a)];
}

export const stringify = (arr) => {
  var r;
  var rLen;
  var c;
  var cLen;
  var str = '';
  var val;

  for (r = 0, rLen = arr.length; r < rLen; r += 1) {
    cLen = arr[r].length;

    for (c = 0; c < cLen; c += 1) {
      if (c > 0) {
        str += '\t';
      }

      val = arr[r][c];

      if (typeof val === 'string') {
        if (val.indexOf('\n') > -1) {
          str += "\"".concat(val.replace(/"/g, '""'), "\"");
        } else {
          str += val;
        }
      } else if (val === null || val === void 0) {
        // void 0 resolves to undefined
        str += '';
      } else {
        str += val;
      }
    }

    if (r !== rLen - 1) {
      str += '\n';
    }
  }

  return str;
}
