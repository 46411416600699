import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { MainPageColRowModel, mainPageColRowModelDefault } from "@/components/mainpage/MainPageTableModel";
import { optionAll, ZaikoKbn } from "@/store/mainpage/mainPageCommon";
import { OptionTerm } from "@/components/custom/TermOptionSelecter";
import moment from "moment";

//強制表示列
export const mustVisibleColumnsKey: string[] = [
  "no",
  'loss',
];
//デフォルト表示列
export const defaultVisibleColumnsKey: string[] = [
  'centerNM',
  'makerNM',
  'itemNM',
  'janCD',
  'itemCD',
  'syoriKbn',
  'furikaeDate',
  'furikaeFrom',
  'furikaeTo',
  'furikaeBL',
  'loss',
  'biko',
];

export const optionsNameList = [
  'optionBumons',
  'optionCenters',
  'optionCenterCode',

  'optionMakers',
  'optionMakerCode',
  'optionItemCode',

  'optionFurikaeTerm',
  'optionSyoriKbn',
  'optionFurikaeFrom',
  'optionFurikaeTo',
  'optionHonbuCode',

  'optionInputStatus',

  'optionReasons',
];
export type MainPageOptions = {
  optionBumons?: CodeName[],    //部門
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,    //倉庫CD

  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,     //メーカーCD
  optionItemCode?: string,      //商品CD

  optionFurikaeTerm?: OptionTerm,   //処理日（振替日）
  optionSyoriKbn?: '' | '1' | '5' | string,   //処理区分
  optionFurikaeFrom?: ZaikoKbn[],   //
  optionFurikaeTo?: ZaikoKbn[],   //
  optionHonbuCode?: string | null,

  optionInputStatus?: '' | '1' | '3' | '4' | string,   //入力状況 1:未入力 3:入力済 4:要確認

  optionReasons?: CodeName[],  //ロス理由
};

export const displaySettingsNameList = [
  'checkedColKey',
];

export type DisplaySettings = {
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type MainPageSaveState = {
  //検索条件
  optionBumons: CodeName[],     //部門
  optionCenters: CodeName[],    //倉庫
  optionCenterCode: string,     //倉庫CD

  optionMakers: CodeName[],     //メーカー
  optionMakerCode: string,      //メーカーCD
  optionItemCode: string,       //商品CD

  optionFurikaeTerm: OptionTerm | null,    //処理日（振替日）
  optionSyoriKbn: '' | '1' | '5' | string,   //処理区分
  optionFurikaeFrom: ZaikoKbn[],   //
  optionFurikaeTo: ZaikoKbn[],   //
  optionHonbuCode: string | null,

  optionInputStatus: '' | '1' | '3' | '4' | string,   //入力状況 1:未入力 3:入力済 4:要確認

  optionReasons: CodeName[] | null,  //ロス理由

  favoriteOptions: { title: CodeName, params: MainPageOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  showSubTotal: 'なし' | 'あり' | 'のみ',        //商品集計を表示

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  colWidthsModified: {},

  sort: { key: string, asc: boolean },
};

export const initialMainPageState: MainPageSaveState = {
  optionBumons: [],   //部門
  optionCenters: [],   //倉庫
  optionCenterCode: '',            //倉庫CD

  optionMakers: [],    //メーカー
  optionMakerCode: '',            //メーカーCD
  optionItemCode: '',            //商品CD

  optionFurikaeTerm: {
    termFrom: moment().startOf('day').add(-1, 'day').toDate(),
    termTo: moment().startOf('day').add(-1, 'day').toDate(),
  },                              //処理日（振替日）
  optionSyoriKbn: optionAll.code,   //処理区分
  optionFurikaeFrom: [optionAll.code],
  optionFurikaeTo: [optionAll.code],
  optionHonbuCode: '',

  optionInputStatus: '1',   //入力状況 1:未入力

  optionReasons: [],          //ロス理由

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  showSubTotal: 'のみ',        //商品集計を表示

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  colWidthsModified: {},

  sort: {
    key: "itemCD",
    asc: true,
  },
};

//Page Slice
export type MainPageSaveReducer = {
  initOnDidMount: (state: MainPageSaveState) => void,
  resetOnWillUnmount: (state: MainPageSaveState) => void,
  clearOption: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionBumons: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionMakers: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionItemCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionFurikaeTerm: (state: MainPageSaveState, action: PayloadAction<OptionTerm>) => void,
  setOptionSyoriKbn: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionFurikaeFrom: (state: MainPageSaveState, action: PayloadAction<string[]>) => void,
  setOptionFurikaeTo: (state: MainPageSaveState, action: PayloadAction<string[]>) => void,
  setOptionHonbuCode: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionInputStatus: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  setOptionReasons: (state: MainPageSaveState, action: PayloadAction<CodeName[]>) => void,
  setFavoriteOptions: (state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) => void,
  setFavoriteDisplaySettings: (state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setShowSubTotal: (state: MainPageSaveState, action: PayloadAction<string>) => void,
  columnWidthChange: (state: MainPageSaveState, action: PayloadAction<{ colKey: string, width: number }>) => void,
  hideColumnKeysChange: (state: MainPageSaveState, action: PayloadAction<string[]>) => void,
  setSort: (state: MainPageSaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) => void,
}

const createReducerContent = (name: string, colRowModel: MainPageColRowModel, initialState: MainPageSaveState): MainPageSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: MainPageSaveState) {

      if (state.optionBumons === undefined) {
        state.optionBumons = initialMainPageState.optionBumons;
      }
      if (state.optionCenters === undefined) {
        state.optionCenters = initialMainPageState.optionCenters;
      }
      if (state.optionCenterCode === undefined) {
        state.optionCenterCode = initialMainPageState.optionCenterCode;
      }
      if (state.optionMakers === undefined) {
        state.optionMakers = initialMainPageState.optionMakers;
      }
      if (state.optionMakerCode === undefined) {
        state.optionMakerCode = initialMainPageState.optionMakerCode;
      }
      if (state.optionItemCode === undefined) {
        state.optionItemCode = initialMainPageState.optionItemCode;
      }
      if (state.optionFurikaeTerm === undefined) {
        state.optionFurikaeTerm = initialMainPageState.optionFurikaeTerm;
      }
      else {
        if (state.optionFurikaeTerm?.termFrom && typeof state.optionFurikaeTerm.termFrom === 'string') {
          state.optionFurikaeTerm.termFrom = new Date(state.optionFurikaeTerm.termFrom);
        }
        if (state.optionFurikaeTerm?.termTo && typeof state.optionFurikaeTerm.termTo === 'string') {
          state.optionFurikaeTerm.termTo = new Date(state.optionFurikaeTerm.termTo);
        }
      }
      if (state.optionSyoriKbn === undefined) {
        state.optionSyoriKbn = initialMainPageState.optionSyoriKbn;
      }
      if (state.optionFurikaeFrom === undefined) {
        state.optionFurikaeFrom = initialMainPageState.optionFurikaeFrom;
      }
      if (state.optionFurikaeTo === undefined) {
        state.optionFurikaeTo = initialMainPageState.optionFurikaeTo;
      }
      if (state.optionHonbuCode === undefined) {
        state.optionHonbuCode = initialMainPageState.optionHonbuCode;
      }
      if (state.optionInputStatus === undefined) {
        state.optionInputStatus = initialMainPageState.optionInputStatus;
      }
      if (state.optionReasons === undefined) {
        state.optionReasons = initialMainPageState.optionReasons;
      }
      if (state.favoriteOptions === undefined) {
        state.favoriteOptions = initialMainPageState.favoriteOptions;
      }
      if (state.favoriteDisplaySettings === undefined) {
        state.favoriteDisplaySettings = initialMainPageState.favoriteDisplaySettings;
      }
      if (state.showSubTotal === undefined || typeof state.showSubTotal === 'boolean') {
        state.showSubTotal = initialState.showSubTotal;
      }

      //初期表示列0配列は、常に全項目表示とする
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if (!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
      //強制選択列
      state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
      //非表示列
      state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

      if (state.colWidthsModified === undefined) {
        state.colWidthsModified = initialState.colWidthsModified;
      }
      if (state.sort === undefined) {
        state.sort = initialState.sort;
      }
    },
    //componentWillUnmount
    resetOnWillUnmount(state: MainPageSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
    },
    clearOption(state: MainPageSaveState, action: PayloadAction<string>) {
      const key = action.payload;
      // console.log(`clearOption ${key}`);
      if (!key) {
        state.optionInputStatus = initialMainPageState.optionInputStatus;
        state.optionReasons = initialMainPageState.optionReasons;
        state.optionFurikaeTerm = initialMainPageState.optionFurikaeTerm;
        state.optionSyoriKbn = initialMainPageState.optionSyoriKbn;
        state.optionFurikaeFrom = initialMainPageState.optionFurikaeFrom;
        state.optionFurikaeTo = initialMainPageState.optionFurikaeTo;
        state.optionHonbuCode = initialMainPageState.optionHonbuCode;
      }
      if (!key || key === 'item') {
        state.optionMakers = initialMainPageState.optionMakers;
        state.optionMakerCode = initialMainPageState.optionMakerCode;
        state.optionItemCode = initialMainPageState.optionItemCode;
      }
      if (!key || key === 'center') {
        state.optionBumons = initialMainPageState.optionBumons;
        state.optionCenters = initialMainPageState.optionCenters;
        state.optionCenterCode = initialMainPageState.optionCenterCode;
      }
    },
    setOptionBumons(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionBumons = action.payload;
    },
    setOptionCenters(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionCenters = action.payload;
    },
    setOptionCenterCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionCenterCode = action.payload;
    },
    setOptionMakers(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionMakers = action.payload;
    },
    setOptionMakerCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionMakerCode = action.payload;
    },
    setOptionItemCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionItemCode = action.payload;
    },
    setOptionFurikaeTerm(state: MainPageSaveState, action: PayloadAction<OptionTerm>) {
      state.optionFurikaeTerm = action.payload;
    },
    setOptionSyoriKbn(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionSyoriKbn = action.payload;
    },
    setOptionFurikaeFrom(state: MainPageSaveState, action: PayloadAction<string[]>) {
      state.optionFurikaeFrom = action.payload;
    },
    setOptionFurikaeTo(state: MainPageSaveState, action: PayloadAction<string[]>) {
      state.optionFurikaeTo = action.payload;
    },
    setOptionHonbuCode(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionHonbuCode = action.payload;
    },
    setOptionInputStatus(state: MainPageSaveState, action: PayloadAction<string>) {
      state.optionInputStatus = action.payload;
    },
    setOptionReasons(state: MainPageSaveState, action: PayloadAction<CodeName[]>) {
      state.optionReasons = action.payload;
    },
    setFavoriteOptions(state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: MainPageOptions }[]>) {
      state.favoriteOptions = action.payload;
    },
    setFavoriteDisplaySettings(state: MainPageSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
      state.favoriteDisplaySettings = action.payload;
    },
    setShowSubTotal(state: MainPageSaveState, action: PayloadAction<string>) {
      switch (action.payload) {
        case 'なし':
          state.showSubTotal = 'なし';
          break;
        case 'あり':
          state.showSubTotal = 'あり';
          break;
        case 'のみ':
          state.showSubTotal = 'のみ';
          break;
      }
    },
    columnWidthChange(state: MainPageSaveState, action: PayloadAction<{ colKey: string, width: number }>) {
      console.log('store.columnWidthChange');
      const colKey = action.payload.colKey;
      const width = action.payload.width;
      if (colKey && width) {
        state.colWidthsModified[colKey] = width;
      }
    },
    hideColumnKeysChange(state: MainPageSaveState, action: PayloadAction<string[]>) {
      console.log('store.hideColumnKeysChange');
      //store更新
      state.hiddenColumns = colRowModel.colFromKeys(action.payload);
      state.hiddenColumnsKey = action.payload;
      state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
    },
    setSort(state: MainPageSaveState, action: PayloadAction<{ sortKey: string, sortAsc: boolean }>) {
      console.log('setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
  }
};

const createSliceContent = (name: string, colRowModel: MainPageColRowModel, initialState: MainPageSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//mainPageSaveSlice
export const mainPageSaveSlice = createSliceContent("mainPageSave", mainPageColRowModelDefault, initialMainPageState);
