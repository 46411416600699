import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    //primary: {
    //  main: '#556cd6',
    //},
    //secondary: {
    //  main: '#19857b',
    //},
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.78rem',
          lineHeight: 1.28,          
          letterSpacing: '0.01071em',
        },
      },
    },
  },
})

export default theme