export const vocabularies = {
    ja: {
      'Sign In': 'サインイン',
      'Sign Up': 'サインアップ',
      'Sign Out': 'サインアウト',
      'Sign in to your account': 'アカウントにサインイン',
      'Username *': 'ユーザー名 *',
      'Password *': 'パスワード *',
      'Enter your username': 'ユーザー名を入力',
      'Enter your password': 'パスワードを入力',
      'No account?': 'アカウントが未登録ですか？',
    },
  };