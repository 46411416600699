import { CodeName } from "@/assets/commontype/CodeName";
import * as calcUtil from "@/util/calcUtil";
import { } from "./mainPageTmp";
import moment from 'moment';

export type DefaultVisibleKey = {
  name: string,
  visibleKey: string[],
};

export const notEnteredCode = '99'; //未入力
export const optionAll = new CodeName({ code: 'all', name: '全て' });

export type RowData = {
  TP?: "item" | "grouping1",
  // div?: string,
  id?: number,
  no?: string,
  seq?: number,
  uuid?: string,
  editState?: string[],
  grouping1CD?: string,

  //以下に貼り付け
  centerCD?: string | null,
  centerNM?: string | null,
  itemCD?: string | null,
  itemNM?: string | null,
  janCD?: string | null,
  makerCD?: string | null,
  makerNM?: string | null,
  categoryCD?: string | null,
  categoryNM?: string | null,
  blIrisu?: number | null,
  csIrisu?: number | null,
  teika?: string | null,
  tokuisakiCD?: string | null,
  tokuisakiNM?: string | null,

  syoriKbn?: string | null,
  furikaeDate?: string | null,
  furikaeFrom?: string | null,
  furikaeTo?: string | null,

  furikaePS?: number | null,
  furikaeBL?: number | null,
  furikaeKingaku?: number | null,

  furikaeReason?: string | null,
  furikaeReasonCD?: string | null,
  furikaeReasonNM?: string | null,
  loss?: string | null,
  lossCD?: string | null,
  lossNM?: string | null,
  biko?: string | null,
  updateUser?: string | null,
  updateDate?: string | null,

}

export type DiffData = RowData;

//編集情報
export interface SaveInfoData {
  seq?: number,  //
  dataIndex?: number,
  uuid: string,

  // div: string,
  id: number,

  furikaeReasonCD: string,
  lossCD: string,
  biko: string,
  updateDate: string,

  //初回編集判定
  loss_kbn_bak?: string,
}

//入力エラー情報
export interface ErrorInfoData {

}



//入力状況
export const inputStatusName = (value: '1' | '3' | '4' | string) => {
  switch (value) {
    case '1':
      return '未入力';
    case '3':
      return '入力済';
    case '4':
      return '要確認';
    default:
      return '-';
  }
}

export type ZaikoKbn = '' | '1' | '2' | '3' | '8' | '9' | string;

//在庫区画（１：一般　２：特売　３：予約　８：返品　９：出切）
export const zaikoKbnName = (code: string): string => {
  switch (code) {
    case '1':
      return '一般';
    case '2':
      return '特売';
    case '3':
      return '予約';
    case '8':
      return '返品';
    case '9':
      return '出切';
    default:
      return '';
  }
}

export const syoriKbnName = (code: string): string => {
  switch (code) {
    case '1':
      return '一般';
    case '5':
      return '振替';
    default:
      return '';
  }
}


