
export const plus = (n1:number, n2:number) => {
  if(!n1 && !n2) return null;
  if(!n1) return n2;
  if(!n2) return n1;
  return n1 + n2;
}
export const sum = (n:number[]) => {
  let vv;
  n.forEach(v => {vv = plus(vv, v)});
  return vv;
}

export const minus = (n1:number, n2:number) => {
  if(!n1 && !n2) return null;
  if(!n1) return -n2;
  if(!n2) return n1;
  return n1 - n2;
}

export const minus2 = (n1:number, n2:number) => {
  if(!n1 || !n2) return null;
  return n1 - n2;
}

export const divide = (numerator:number, denominator:number) => {
  if(!numerator || !denominator) return null;
  return numerator / denominator;
}

export const divide2 = (numerator:number, denominator:number) => {
  if(!denominator) return null;
  if(!numerator) return 0;
  return numerator / denominator;
}

export const multiply = (n1:number, n2:number) => {
  if(!n1 || !n2) return null;
  return n1 * n2;
}

export const average = (ns:number[], igunoreZero:boolean) => {
  let sum;
  let cnt = 0;
  ns.forEach(n => {
    if(n || !igunoreZero) {
      sum = plus(sum, n);
      cnt++;
    }
  });
  return divide(sum, cnt);
}

//最小公倍数
export const lcm = (a:number,b:number):number => {
  var g = (n, m) => m ? g(m, n % m) : n
  return a * b / g(a, b)
}

//最大公約数
export const gcd = (a:number,b:number):number => {
  if (b === 0){
      return a
  }
  return gcd(b, a % b)
}

//指定数以上の最小の倍数
export const smallestMultiple = (n:number, m:number) => {
  if(!n || !m) return null;
  return Math.ceil(n / m) * m;
 
}
//指定数以下の最大の倍数
export const largestMultiple = (n:number, m:number) => {
  if(!n || !m) return null;
  return Math.trunc(n / m) * m;
 
}

