import "@/styles/globals.scss";
import "@/styles/mainpage.scss";
import React, { useEffect } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import  CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "@/theme";

import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// import { useStore, RootState } from "@/store";
import { makeStore } from "@/store";

import * as storageUtil from "@/util/storageUtil";

import { setCookie } from 'nookies';

import { registerLanguageDictionary, jaJP } from 'handsontable/i18n';

//aws-amplify 翻訳
import { I18n } from 'aws-amplify';
import { vocabularies } from '@/assets/amplify/i18n/vocabularies';
I18n.putVocabularies(vocabularies);
I18n.setLanguage('ja');

function MyApp({ Component, pageProps }: AppProps): JSX.Element {

  //Store 永続化
  //URL共有は保存させない
  const store = makeStore();
  const persistor = persistStore(store)

  //DEF componentDidMount
  const componentDidMount = ()=>{
    console.log('_app.componentDidMount');

    registerLanguageDictionary(jaJP);

    //URLパラメータにcodeが指定されている場合
    if (pageProps['code']) {
      setCookie(null, 'sms_code', pageProps['code'], {
        maxAge: parseInt(process.env.NEXT_PUBLIC_AUTHCODE_ALIVE_MIN) * 60, //3分
      });
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    jssStyles?.parentElement?.removeChild(jssStyles);
  };

  useEffect(() => {
    //DO componentWillUnmount
    componentDidMount.bind(this)();
  }, []); //Empty

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ThemeProvider theme={theme} key={pageProps['pageid']}>
        <CssBaseline />
          <ReduxProvider store={store}>
            {/* @ts-ignore TODO: AREA_CONFEX-297 */}
            <PersistGate persistor={persistor}>
              {/* @ts-ignore TODO: AREA_CONFEX-297 */}
              <Component {...pageProps} />
            </PersistGate>
          </ReduxProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;