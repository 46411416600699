import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'centerCD',
  'centerNM',
  'makerCD',
  'makerNM',
  'itemNM',
  'janCD',
  'itemCD',
  'categoryCD',
  'categoryNM',
  'teika',
  'blIrisu',
  'csIrisu',
  'tokuisakiCD',
  'tokuisakiNM',
  'syoriKbn',
  'furikaeDate',
  'furikaeFrom',
  'furikaeTo',
  'furikaeKingaku',
  'furikaePS',
  'furikaeBL',
  'furikaeReason',
  'loss',
  'biko',
  'updateUser',
  'updateDate',
];

export const headersCol = [
  {
    no: '　',
    centerCD: '倉庫',
    centerNM: '倉庫',
    itemCD: '商品属性',
    itemNM: '商品属性',
    janCD: "商品属性",
    makerCD: "商品属性",
    makerNM: "商品属性",
    categoryCD: "商品属性",
    categoryNM: "商品属性",
    blIrisu: "商品属性",
    csIrisu: "商品属性",
    teika: "商品属性",
    tokuisakiCD: '得意先',
    tokuisakiNM: '得意先',

    syoriKbn: '',
    furikaeDate: '　',
    furikaeFrom: '　',
    furikaeTo: '　',
    furikaePS: '　',
    furikaeBL: '　',
    furikaeKingaku: '　',

    furikaeReason: '振替理由',
    loss: 'ロス理由',
    biko: 'ロス理由',

    updateUser: '登録状況',
    updateDate: '登録状況',
  },
  {
    no: 'No.',
    centerCD: 'CD',
    centerNM: '名称',
    itemCD: '商品CD',
    itemNM: '商品名',
    janCD: "JAN",
    makerCD: "ﾒｰｶｰCD",
    makerNM: "ﾒｰｶｰ名",
    categoryCD: "ｶﾃｺﾞﾘCD",
    categoryNM: "ｶﾃｺﾞﾘ名",
    blIrisu: "BL入数",
    csIrisu: "CS入数",
    teika: "定価",

    tokuisakiCD: 'CD',
    tokuisakiNM: '名称',

    syoriKbn: '処理区分',
    furikaeDate: '振替日',
    furikaeFrom: '振替元',
    furikaeTo: '振替先',
    furikaePS: '振替バラ',
    furikaeBL: '振替BL',
    furikaeKingaku: '振替金額',

    furikaeReason: '振替理由',
    loss: 'ロス理由',
    biko: '備考',

    updateUser: '更新者',
    updateDate: '更新日時',
  },
];


export const selectableCol =
{
  no: false,
  centerCD: true,
  centerNM: true,
  itemCD: true,
  itemNM: true,
  janCD: true,
  makerCD: true,
  makerNM: true,
  categoryCD: true,
  categoryNM: true,
  blIrisu: true,
  csIrisu: true,
  teika: true,
  tokuisakiCD: true,
  tokuisakiNM: true,
  syoriKbn: true,
  furikaeDate: true,
  furikaeFrom: true,
  furikaeTo: true,
  furikaePS: true,
  furikaeBL: true,
  furikaeKingaku: true,
  furikaeReason: true,
  loss: false,
  biko: true,
  updateUser: true,
  updateDate: true,
}


export const headerColClasses = [
  {
    no: 'header_noBorder_no',
    centerCD: 'header',
    centerNM: 'header',
    itemCD: 'header',
    itemNM: 'header',
    janCD: 'header',
    makerCD: 'header',
    makerNM: 'header',
    categoryCD: 'header',
    categoryNM: 'header',
    blIrisu: 'header',
    csIrisu: 'header',
    teika: 'header',
    tokuisakiCD: 'header',
    tokuisakiNM: 'header',
    syoriKbn: 'header',
    furikaeDate: 'header',
    furikaeFrom: 'header',
    furikaeTo: 'header',
    furikaePS: 'header',
    furikaeBL: 'header',
    furikaeKingaku: 'header',
    furikaeReason: 'header_loss',
    loss: 'header_loss',
    biko: 'header_loss',
    updateUser: 'header',
    updateDate: 'header',
  },
  {
    no: 'header_no',
    centerCD: 'header',
    centerNM: 'header',
    itemCD: 'header',
    itemNM: 'header',
    janCD: 'header',
    makerCD: 'header',
    makerNM: 'header',
    categoryCD: 'header',
    categoryNM: 'header',
    blIrisu: 'header',
    csIrisu: 'header',
    teika: 'header',
    tokuisakiCD: 'header',
    tokuisakiNM: 'header',
    syoriKbn: 'header',
    furikaeDate: 'header',
    furikaeFrom: 'header',
    furikaeTo: 'header',
    furikaePS: 'header',
    furikaeBL: 'header',
    furikaeKingaku: 'header',
    furikaeReason: 'header_loss',
    loss: 'header_loss',
    biko: 'header_loss',
    updateUser: 'header',
    updateDate: 'header',
  },
];

export const colWidths =
{
  no: 40,
  centerCD: 50,
  centerNM: 140,
  itemCD: 100,
  itemNM: 240,
  janCD: 120,
  makerCD: 80,
  makerNM: 140,
  categoryCD: 100,
  categoryNM: 140,
  blIrisu: 60,
  csIrisu: 60,
  teika: 60,
  tokuisakiCD: 90,
  tokuisakiNM: 200,
  syoriKbn: 80,
  furikaeDate: 80,
  furikaeFrom: 80,
  furikaeTo: 80,
  furikaePS: 80,
  furikaeBL: 80,
  furikaeKingaku: 80,
  furikaeReason: 200,
  loss: 200,
  biko: 200,
  updateUser: 80,
  updateDate: 120,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, },
  centerCD: { type: 'text', readOnly: true, className: 'htCenter', },
  centerNM: { type: 'text', readOnly: true, },
  itemCD: { type: 'text', readOnly: true, className: 'htCenter', },
  itemNM: { type: 'text', readOnly: true, },
  janCD: { type: 'text', readOnly: true, },
  makerCD: { type: 'text', readOnly: true, className: 'htCenter', },
  makerNM: { type: 'text', readOnly: true, },
  categoryCD: { type: 'text', readOnly: true, className: 'htCenter', },
  categoryNM: { type: 'text', readOnly: true, },
  blIrisu: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  csIrisu: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  teika: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  tokuisakiCD: { type: 'text', readOnly: true, className: 'htLeft', },
  tokuisakiNM: { type: 'text', readOnly: true, },
  syoriKbn: { type: 'text', readOnly: true, className: 'htCenter', },
  furikaeDate: { type: 'text', readOnly: true, className: 'htCenter', },
  furikaeFrom: { type: 'text', readOnly: true, className: 'htCenter', },
  furikaeTo: { type: 'text', readOnly: true, className: 'htCenter', },
  furikaePS: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  furikaeBL: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0.#', }, className: 'htRight', },
  furikaeKingaku: { type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, className: 'htRight', },
  furikaeReason: { type: 'dropdown', source: [], readOnly: false, },
  loss: { type: 'dropdown', source: [], readOnly: false, },
  biko: { type: 'text', },
  updateUser: { type: 'text', readOnly: true, className: 'htCenter', },
  updateDate: { type: 'text', readOnly: true, className: 'htCenter', },
};


//列定義情報（動的パラメータ）
interface MainPageColRowModelInterface {
  //行固定
  fixedRowsTop: number;
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
}

export class MainPageColRowModel implements MainPageColRowModelInterface {
  //行固定
  fixedRowsTop: number;
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;

  constructor(param: MainPageColRowModelInterface) {
    this.fixedRowsTop = param.fixedRowsTop;
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan = 0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if (lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if (preHeaderVal !== null && preHeaderVal != headerVal) {
            nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
            colspan = 0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if (index == 0) {
          nestedHeadersGroupIndex.push(colspan - 1);
        }
      });
      if (!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column: number): string {
    return this.colKeys[column];
  }
  keyFromCols(columns: number[]): string[] {
    const vals: string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey: string): number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys: string[]): number[] {
    const vals: number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }

}

//MainPage用の列モデル
export const mainPageColRowModelDefault: MainPageColRowModel = new MainPageColRowModel({
  //行固定
  fixedRowsTop: 0,
  //列固定
  fixedColumnsLeft: 6,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
  ],
});

